<template>
  <div class="vue-template h-100 bg-light">
    <div class="row h-100" v-if="!$userData && !busy">
      <div class="col-md-3 h-100 bg-light p-5 d-none d-lg-block d-xl-block">
        &nbsp;
      </div>
      <div class="col h-100 bg-white p-5">
        <img width="80px" class="mx-5" alt="iRipple" src="@/assets/images/iripple-logo-primary.png">
        <div class="p-3 pt-0">
          <div class="row pb-5 my-4 login-form">
            <div class="col-md-1">
              &nbsp;
            </div>
            <div class="col-md-10 py-5" v-if="user && isValid">
              <h3 class="text-header-static text-dark">Reset Password</h3>
              <p class="text-escalated">You have requested for a password reset. Enter your new password below.</p>
              <div class="mt-4">
                <div class="form-group my-3">
                  <input v-on:keyup.enter="validateForm" v-model="email" type="email" placeholder="Confirm email address" class="form-control text-escalated p-3"/>
                </div>
                <div class="form-group my-3">
                  <input v-on:keyup.enter="validateForm" v-model="newPassword" type="password" placeholder="New password" class="form-control text-escalated p-3" />
                </div>
                <div class="form-group my-3">
                  <input v-on:keyup.enter="validateForm" v-model="confirmPassword" type="password" placeholder="Confirm password" class="form-control text-escalated p-3" />
                </div>
                <div v-on:click="validateForm" class="btn btn-primary-dark btn-block btn-100 p-4 text-header text-white">
                  <i class="fas fa-unlock"></i>&nbsp;&nbsp;Change password
                </div>
              </div>
              <div class="col-md-2">
              </div>
            </div>
            <div class="col-md-10 p-5" v-else>
              <p class="p-5 text-escalated">
                Invalid request token. Please try again
              </p>
            </div>
            <div class="col-md-1">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 h-100 bg-light p-5 d-none d-lg-block d-xl-block">
        &nbsp;
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        errors: [],
        user: null,
        email: '',
        newPassword: '',
        confirmPassword: '',
        busy: true,
        isValid: false
      }
    },
    props: {
      token: String
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      validateForm: function(){
        this.errors = []
        this.email = this.email.trim()
        this.newPassword = this.newPassword.trim()
        this.confirmPassword = this.confirmPassword.trim()
        if(this.email === '')
          this.errors.push('Email cannot be empty')
        if(!this.$validateEmail(this.email))
          this.errors.push('Invalid email address')
        if(this.email !== this.user.email)
          this.errors.push('Email address does not match')
        if(this.newPassword.length <= 0 || (this.newPassword.length > 0 && this.newPassword.length < 6))
          this.errors.push('Password must have 6 or more characters')
        if(this.confirmPassword !== this.newPassword)
          this.errors.push('Confirm password does not match new password')
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'User login',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitReset({
            user: this.user,
            password: this.newPassword
          })
        }
      },
      async submitReset(data) {
        this.busy = true
        this.loader(true);
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/user/password/${this.user._id}`,
            data
          );
          if(response && response.data && response.data.isValid){
            this.email = ''
            this.newPassword = ''
            this.confirmPassword = ''
            this.$alertify({
              group: 'notification',
              title: 'Password reset',
              type: 'success',
              text: 'Change password successful'
            })
            await this.$sleep(1500);
            window.location.href = '/'
          }
          else{
            this.$alertify({
              group: 'notification',
              title: 'Password reset',
              type: 'error',
              text: 'Something went wrong. Please try again'
            })
          }
        } catch (error) {
          this.busy = false
          this.$alertify({
            group: 'notification',
            title: 'Password reset',
            type: 'error',
            text: 'Something went wrong. Please try again'
          })
        }
        this.busy = false
        this.loader(false);
      },
      validateToken: async function(){
        if(this.$userData && this.$userData.user){
          this.$alertify({
            group: 'notification',
            title: 'Password reset',
            type: 'warn',
            text: 'You are currently logged in on an account. Please log out to continue.'
          })
          await this.$sleep(1500);
          window.location.href = '/'
        }
        this.busy = true
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/user/checkToken/${this.token}`
          );
          let error = null
          if(response && response.data){
            if(!response.data.isValid)
              error = "This request has expired. Please try another one."
            if(!response.data.user)
              error = "Invalid request token. Please try again"
          }
          if(!error){
            this.user = response.data.user
            this.isValid = response.data.isValid
          } else {
            this.$alertify({
              group: 'notification',
              title: 'Password reset',
              type: 'error',
              text: error
            })
            await this.$sleep(1500);
            window.location.href = '/'
          }
        } catch (error) {
          this.isValid = false
          this.$alertify({
            group: 'notification',
            title: 'Password reset',
            type: 'error',
            text: 'Something went wrong. Please refresh the page to continue.'
          })
        }
        this.busy = false
        this.loader(false);
      }
    },
    mounted: function() {
      this.busy = false
      this.validateToken()
      document.title = `Reset Password | iRipple Helpdesk`;
    }
  }
</script>
